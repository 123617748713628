/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checkbox-off': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M14.269 1.176h.001c1.107.43 1.8 1.262 2.223 2.013a5.842 5.842 0 01.526 1.268l.019.075.004.017v.002h0v.001h0l.002.006.001.008c.361 1.5.471 3.045.453 4.437h0v.013a18.399 18.399 0 01-.472 4.438h0l-.003.012h0v.001h0v.002l-.004.017c-.004.016-.01.042-.02.076a5.841 5.841 0 01-.525 1.268c-.422.75-1.115 1.582-2.223 2.012h0l-.003.002c-1.08.429-3.14.637-5.248.656-2.1-.02-4.167-.245-5.253-.658-1.106-.43-1.8-1.262-2.222-2.012A5.846 5.846 0 011 13.561a2.69 2.69 0 01-.019-.076l-.004-.017v-.001h0v-.001h0l-.002-.009-.003-.01c-.378-1.516-.488-3.06-.47-4.45h0v-.014c-.018-1.368.11-2.915.472-4.438h0l.003-.013h0V4.53l.004-.016c.004-.016.01-.042.02-.076a5.845 5.845 0 01.525-1.268c.421-.75 1.115-1.581 2.221-2.012C4.833.744 6.9.518 9 .5c2.102.019 4.167.245 5.269.676z" _stroke="#898B9B"/>'
  }
})
